.center-header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.alert-custom-purple {
    background-color: rgba(128, 0, 128, 0.3);
    color: purple; /* Puedes ajustar el color del texto según tus preferencias */
}

.alert-custom-yellow {
    background-color: rgba(255, 255, 0, 0.3); /* Amarillo con transparencia */
    color: black; /* Puedes ajustar el color del texto según tus preferencias */
}

.alert-custom-navy {
    background-color: rgba(0, 0, 128, 0.3); /* Azul marino con transparencia */
    color: black; /* Puedes ajustar el color del texto según tus preferencias */
}

.alert-custom-brown {
    background-color: rgba(139, 69, 19, 0.3); /* Café con transparencia */
    color: black; /* Puedes ajustar el color del texto según tus preferencias */
}

.descripcion-container {
    max-height: 400px;
    overflow-y: auto; /* Agrega una barra de desplazamiento vertical si el contenido supera la altura máxima */
    border: 1px solid #ccc; /* Puedes personalizar el borde según tus preferencias */
    padding: 10px; /* Puedes ajustar el espaciado interno según tus preferencias */
}

.btn-link-gray {
    color: #6c757d; /* Cambia este color al gris deseado */
  }

.respuesta {
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
}

.usuario {
    background-color: #f0f0f0; /* Color de fondo para respuestas del usuario */    
    text-align: right;
}

.admin {
    background-color: #d9edf7; /* Color de fondo para respuestas de administrador */
    text-align: left;
}

.badge {
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;    
    top: -5px; /* Ajusta la posición vertical según sea necesario */
    right: 0; /* Ajusta la posición horizontal según sea necesario */
  }

.file-container {
    display: flex; /* Usar flexbox para alinear elementos */
    align-items: center; /* Centrar verticalmente los elementos */
}

.file-icon {
    /* Estilos para el icono, como el tamaño de la imagen */
}

.file-name {
    margin-left: 8px; /* Espacio entre el icono y el nombre */
    /* Otros estilos para el nombre del archivo */
}